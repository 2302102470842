
































































































import useAccessory from "@/use/accessory";
import useUser from "@/use/user";
import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
    MInventoryUserAssignments: () =>
      import("@/components/molecules/m-inventory-user-assignments.vue"),
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const assignments = ref();

    const { getAccessoryIcon } = useAccessory();

    const model = reactive({
      company: null as any,
      search: computed({
        get: () => root.$route.query["users.search"] as string,
        set: (value) =>
          root.$router
            .replace({
              query: {
                ...root.$route.query,
                "users.search": value || undefined,
              },
            })
            .catch(() => []),
      }),
    });

    const state = reactive({
      loading: false,
      headers: computed(() => {
        const headers = [];

        headers.push({ value: "user", text: "Użytkownik", sortable: false });
        headers.push({ value: "vehicle", text: "Pojazdy", sortable: false });
        headers.push({ value: "items", text: "Akcesoria", sortable: false });
        headers.push({
          value: "action",
          text: "",
          width: 1,
          sortable: false,
          align: "right",
        });

        return headers;
      }),
      items: [],
      total: 0,
      options: computed({
        get: () => ({
          page: parseInt(root.$route.query["users.page"] as string) || 1,
          itemsPerPage:
            parseInt(root.$route.query["users.itemsPerPage"] as string) || 10,
        }),
        set: (value) => {
          root.$router
            .replace({
              query: {
                ...root.$route.query,
                "users.page": value.page.toString(),
                "users.itemsPerPage": value.itemsPerPage.toString(),
              },
            })
            .catch(() => []);
        },
      }),
    });

    const fetch = () => {
      state.loading = true;

      axiosInstance
        .get("inventory/user", {
          params: {
            company: model.company?.id || undefined,
            search: model.search || undefined,
            page: state.options.page || undefined,
            itemsPerPage: state.options.itemsPerPage || undefined,
          },
        })
        .then(({ data: { users, total } }) => {
          state.items = users;
          state.total = total;
        })
        .catch(() => {
          state.items = [];
          state.total = -1;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced([model, () => state.options], fetch, {
      deep: true,
      immediate: true,
      debounce: 500,
      maxWait: 5000,
    });

    const refresh = () => {
      fetch();
      if (assignments.value && "refresh" in assignments.value)
        assignments.value.refresh();
    };

    const getVehicleAssigmnents = (user: any) =>
      user.vehicleAssignments.filter((assignment: any) => !!assignment.vehicle);

    return {
      assignments,
      getAccessoryIcon,
      hasAccessTo,
      hasManyCompanies,
      state,
      model,
      refresh,
      getVehicleAssigmnents,
    };
  },
});
