import { computed } from "@vue/composition-api";

export enum AccesoryType {
  Phone = "phone",
  Laptop = "laptop",
  Other = "other",
}

export default function useAccessory() {
  const accessoryIcons: { [key in AccesoryType]: string } = {
    [AccesoryType.Phone]: "mdi-cellphone",
    [AccesoryType.Laptop]: "mdi-laptop",
    [AccesoryType.Other]: "mdi-book",
  };

  const accessoryNames: { [key in AccesoryType]: string } = {
    [AccesoryType.Phone]: "Smartfon",
    [AccesoryType.Laptop]: "Laptop",
    [AccesoryType.Other]: "Inne",
  };

  const accessories = computed<
    {
      value: AccesoryType;
      text: string;
      icon: string;
    }[]
  >(() =>
    Object.values(AccesoryType).map((value) => ({
      value,
      text: accessoryNames[value],
      icon: accessoryIcons[value],
    }))
  );

  const getAccessoryIcon = (type: AccesoryType) =>
    accessoryIcons[type] ?? accessoryIcons[AccesoryType.Other];

  const getAccessoryName = (type: AccesoryType) =>
    accessoryNames[type] ?? accessoryNames[AccesoryType.Other];

  return { accessories, getAccessoryIcon, getAccessoryName };
}
