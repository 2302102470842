var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-inventory-users"},[_c('v-card-text',[_c('v-row',[(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Szukaj","append-icon":"mdi-magnify"},model:{value:(_vm.model.search),callback:function ($$v) {_vm.$set(_vm.model, "search", $$v)},expression:"model.search"}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"show-expand":"","single-expand":""},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":function (_, ref) {
	var expand = ref.expand;
	var isExpanded = ref.isExpanded;

	return expand(!isExpanded);
}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('m-inventory-user-assignments',{key:item.id,ref:"assignments",attrs:{"id":item.id,"company":_vm.model.company ? _vm.model.company.id : undefined},on:{"action":function (v) { return _vm.$emit('action', v); }}})],1)]}},{key:"group.header",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [((_vm.hasAccessTo('employee') || _vm.hasManyCompanies()) && items[0])?[_c('td',[(items[0].company)?_c('v-icon',[_vm._v("mdi-domain")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('td',{attrs:{"colspan":headers.length - 1}},[(items[0].company)?_c('strong',[_vm._v(_vm._s(items[0].company.name))]):_c('strong',[_vm._v("Pozostali użytkownicy")])])]:_vm._e()]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [(_vm.getVehicleAssigmnents(item) && _vm.getVehicleAssigmnents(item).length)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((_vm.getVehicleAssigmnents(item).slice(0, 2)),function(assignment){return _c('v-tooltip',{key:assignment.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"small":"","label":"","close":!assignment.returnDate,"close-icon":"mdi-link-off"},on:{"click:close":function($event){return _vm.$emit('action', { type: 'vehicleReturn', user: item, vehicle: assignment.vehicle, step: 4 })}}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-car")]),_c('span',[_vm._v(_vm._s(assignment.vehicle ? assignment.vehicle.details.registrationNumber : null))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(assignment.vehicle ? assignment.vehicle.details.vehicleBrand : null)+" "+_vm._s(assignment.vehicle ? assignment.vehicle.details.vehicleModel : null))])])}),(_vm.getVehicleAssigmnents(item).length > 2)?_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_vm._v("+"+_vm._s(_vm.getVehicleAssigmnents(item).length - 2))])]}}],null,true)},[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticClass:"px-3"},[_vm._v("Pojazdy użytkownika")]),_vm._l((_vm.getVehicleAssigmnents(item)),function(assignment){return _c('v-list-item',{key:assignment.id},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(assignment.vehicle ? assignment.vehicle.details.registrationNumber : null))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(assignment.vehicle ? assignment.vehicle.details.vehicleBrand : null)+" "+_vm._s(assignment.vehicle ? assignment.vehicle.details.vehicleModel : null))])],1),_c('v-list-item-icon',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('action', { type: 'vehicleReturn', user: item, vehicle: assignment.vehicle, step: 4 })}}},[_vm._v("mdi-link-off")])],1)],1)})],2)],1):_vm._e()],2):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.items",fn:function(ref){
var item = ref.item;
return [(item.accessoryAssignments && item.accessoryAssignments.length)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.accessoryAssignments.slice(0, 2)),function(assignment){return _c('v-tooltip',{key:assignment.id,attrs:{"top":"","disabled":!assignment.accessory.serial},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"small":"","label":"","close":!assignment.returnDate,"close-icon":"mdi-link-off"},on:{"click:close":function($event){return _vm.$emit('action', { type: 'accessoryReturn', user: item, accessory: assignment.accessory, step: 4 })}}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.getAccessoryIcon(assignment.accessory.type)))]),_c('span',[_vm._v(_vm._s(assignment.accessory.name))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(assignment.accessory.serial))])])}),(item.accessoryAssignments.length > 2)?_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_vm._v("+"+_vm._s(item.accessoryAssignments.length - 2))])]}}],null,true)},[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticClass:"px-3"},[_vm._v("Akcesoria użytkownika")]),_vm._l((item.accessoryAssignments),function(assignment){return _c('v-list-item',{key:assignment.id},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.getAccessoryIcon(assignment.accessory.type)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(assignment.accessory.name))])],1),_c('v-list-item-icon',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('action', { type: 'accessoryReturn', user: item, accessory: assignment.accessory, step: 4 })}}},[_vm._v("mdi-link-off")])],1)],1)})],2)],1):_vm._e()],2):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticClass:"px-3"},[_vm._v("Działanie")]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('action', { type: 'vehicleHandover', user: item, step: 3 })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-arrow-right")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Wydanie pojazdu")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.getVehicleAssigmnents(item).length <= 0},on:{"click":function($event){return _vm.$emit('action', { type: 'vehicleReturn', user: item, step: 3 })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-arrow-left")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Zdanie pojazdu")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('action', { type: 'accessoryHandover', user: item, step: 3 })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book-arrow-right")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Wydanie akcesorium")])],1)],1),_c('v-list-item',{attrs:{"disabled":item.accessoryAssignments.length <= 0},on:{"click":function($event){return _vm.$emit('action', { type: 'accessoryReturn', user: item, step: 3 })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book-arrow-left")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Zdanie akcesorium")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }